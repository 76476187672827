<template>
  <TransitionRoot as="template" :show="showing">
    <Dialog as="div" class="mobile-navigation fixed inset-0 z-[1999] overflow-hidden xl:hidden" @close="$emit('close-nav')">
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild enter="duration-[200ms] ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-100 ease-in" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="bg-black/60 fixed inset-0" />
        </TransitionChild>

        <div class="w-full max-w-[500px] fixed inset-y-0 left-0 flex">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="-translate-x-full">
            <div class="w-screen overflow-y-auto">
              <div class="h-[80px] absolute inset-0 bg-white z-10"></div>
              <div class="overflow-y-auto bg-white h-full px-[20px] pt-[80px] pb-0 z-5">
                <div class="appointment-nav-link__mobile z-20" v-if="appointmentLink"><a :href="appointmentLink" target="_blank" rel="noopener" class="btn">Book an Appointment</a></div>

                <button class="mobile-nav-toggle z-20" @click="$emit('close-nav')">
                  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.9 56.93" style="width: 20px"><path class="cls-1" d="M75.9,2.71H0V0H75.9Zm0,27.11H0V27.11H75.9Zm0,27.11H0V54.22H75.9Z" /></svg>
                </button>

                <ul>
                  <li class="mobile-search">
                    <form action="/search" class="search-form" @submit.prevent="checkForSpecialKeywords" ref="searchForm">
                      <input type="text" name="query" placeholder="Search" v-model="terms" required />
                      <input type="submit" value="Search" class="btn btn--outline" />
                    </form>
                  </li>
                  <slot name="links"></slot>
                </ul>
                <slot name="retailers"></slot>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";

export default {
  props: {
    showNav: {
      required: true,
      type: Boolean,
    },
    appointmentLink: {
      required: false,
      type: String,
    },
    redirects: {
      type: Array,
      default: false,
    },

  },
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  watch: {
    // work around for the fact that the animation won't fire on the first render when the value is passed as prop.
    showNav(val) {
      if (val) {
        this.$nextTick(() => (this.showing = true));
        return;
      }
      this.showing = false;
    },
  },
  data() {
    return {
      showing: false,
      terms: "",
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.terms = urlParams.get("query")?.toLowerCase();
    this.showing = this.showNav;
  },
  methods: {
    toggleNav() {
      this.showing = !this.showing;
    },
    checkForSpecialKeywords() {
      let redirectTo = null;
      this.redirects.map((redirect) =>   {
        if (this.terms.toLowerCase().includes(redirect.searchQuery.toLowerCase())) {
          redirectTo = redirect.redirectUrl;
        }
      });
      if (redirectTo) {
        window.location.href = redirectTo;
        return false;
      }
      this.$refs.searchForm.submit();
    },

  },
};
</script>

<style scoped>
.btn {
  background: rgb(244,243,240);
  font-size: 0.75rem;
  display: inline-block;
  padding: 0.5rem 2rem;
  color: var(--primary);
  transition: background 0.25s;
  font-family: var(--font-family--sans);
  font-size: 0.875rem;
  text-decoration: none;
  &--outline {
    border: 1px solid var(--primary);
    background: none;
  }
}

button {
  -webkit-appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  background: inherit;
}
input[type="text"] {
  width: 100%;
  display: block;
  font-size: 0.9375rem;
  border: 1px solid #e1e1e1;
  padding: 0.625rem;
  margin-bottom: 0.75rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}

input[type="submit"] {
  display: inline-block;
  margin-top: 0.5rem;
  padding: 0.5rem 2rem;
  color: var(--primary);
  font-family: var(--font-family--sans);
  font-size: 0.875rem;
  font-weight: 400;
  border: 1px solid var(--primary);
  background: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}

.rolex-logo--mobile {
  width: 143px;
  margin-left: -1px;
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
}

.pp-logo--mobile {
  width: 140px;
  padding-bottom: 3rem;
}

@media only screen and (min-width: 1000px) {
  .mobile-nav-toggle,
  .mobile-navigation {
    display: none;
  }
}

</style>
