<template>
  <div class="grid grid-cols-[4rem_auto]" v-if="loading" key="pre-loaded">
    <div :alt="lineItem.description" class="h-16 w-16 flex-none rounded-md border border-gray-200 flex items-center justify-center bg-gray-200 animate-pulse">
      <photo-icon class="h-8 w-8 text-gray-400" />
    </div>

    <div class="ml-4 flex-auto">
      <div class="w-full bg-gray-400 h-3 rounded animate-pulse mb-2"></div>
      <div class="w-4/5 bg-gray-400 h-3 rounded animate-pulse mb-6"></div>
      <div class="w-2/5 bg-gray-400 h-3 rounded animate-pulse"></div>
    </div>
  </div>

  <div class="grid grid-cols-[4rem_auto] group" v-else key="loaded">
    <a :href="url"><img :src="image ?? null" :alt="title" class="h-16 w-16 flex-none rounded-md border border-gray-200" /></a>
    <div class="ml-4 flex-auto">
      <h3 class="">
        <a :href="url"
          ><span v-if="qty > 1">{{ qty }} x </span>{{ title }}</a
        >
      </h3>
      <p class="mb-1 line-through" v-if="salePrice" >{{ price }}</p>
      <p class="mb-0">{{ (salePrice ?? price) }} <button class="ml-2 hover:underline lg:hidden group-hover:inline-block" @click.prevent="$emit('remove', lineItemId)">remove</button></p>
    </div>
  </div>
</template>

<script setup>
import { PhotoIcon } from "@heroicons/vue/24/outline";
import { inject, ref } from "vue";

const props = defineProps({
  lineItem: {
    type: Object,
    required: true,
  },
  saleActive: {
    type: Boolean,
    required: true,
  },
});

const axios = inject("axios");
const loading = ref(true);
const image = ref("");
const title = ref("");
const url = ref("");
const price = ref("");
const salePrice = ref(null);
const qty = ref(props.lineItem.qty);
const lineItemId = props.lineItem.id;
const type = props.lineItem.snapshot.type;
const id = (props.lineItem.snapshot.product ? props.lineItem.snapshot.product.id : props.lineItem.snapshot.id);


const query = `{
          products(id: "` +
  id +
  `") {
            title
            uri
            ... on dbaImported_Product {
              jcSalePrice
              clearance
              productImage1(limit: 1) {
                url @transform(handle: "smallCartImage")
              }
            }
            ... on items_Product {
              jcSalePrice
              clearance
              productImages(limit: 1) {
                url @transform(handle: "smallCartImage")
              }
              defaultVariant {
                priceAsCurrency
                salePriceAsCurrency
              }
            }
            ... on tagHeuerWatch_Product {
              tagProductImages(limit: 1) {
                url @transform(handle: "smallCartImage")
              }
              defaultVariant {
                priceAsCurrency
                salePriceAsCurrency
              }
            }
          }
        }`;


  axios
    .post("/api", { query: query }, { headers: { Authorization: window.frontEndToken } })
    .then((response) => {
      const product = response.data.data.products[0];
      if (!product) {
        image.value = null;
        title.value = null;
        url.value = null;
        price.value = null;
        loading.value = false;
        return;
      }
      image.value='/assets/images/gray.png';
      if (typeof product.productImages === 'object') {
        image.value = product.productImages[0]?.url;
      }
      if (typeof product.productImage1 === 'object') {
        image.value = product.productImage1[0]?.url;
      }
      if (typeof product.tagProductImages === 'object') {
        image.value = product.tagProductImages[0]?.url;
      }

      // console.log(product);
      title.value = product.title;
      url.value = product.uri;
      price.value = product.defaultVariant.priceAsCurrency;
      if (product.jcSalePrice && props.saleActive) { salePrice.value = product.jcSalePrice.toLocaleString("en-GB", {
          style: "currency",
          currency: "GBP",
        }); }
      if (product.defaultVariant.salePriceAsCurrency != product.defaultVariant.priceAsCurrency) { salePrice.value = product.defaultVariant.salePriceAsCurrency; }
      if (product.clearance && product.jcSalePrice) {
        salePrice.value = product.jcSalePrice.toLocaleString("en-GB", {
          style: "currency",
          currency: "GBP",
        });
      }
      loading.value = false;
    })
    .catch(function (error) {
      console.log(error);
      // not a huge amount we can do if the cart isn't loading so lets just log the issue.
      window.bugsnag.notify(error);
    });
</script>
