<template>
  <Disclosure v-slot="{ open }" :defaultOpen="defaultOpen">
    <div class="header-search" @click="setFocus">
      <DisclosureButton id="searchButton">
        <div class="header-search__icon">
          <MagnifyingGlassIcon class="h-6 w-6" stroke-width="1.5" aria-hidden="true" />
        </div>
      </DisclosureButton>
      <DisclosurePanel>
        <form action="/search" class="search-form" @submit.prevent="checkForSpecialKeywords" ref="searchForm">
          <input type="text" name="query" placeholder="Search" v-model="terms" required ref="searchInput" />
        </form>
      </DisclosurePanel>
    </div>
  </Disclosure>
</template>

<script setup>


import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ref } from "vue";

const urlParams = new URLSearchParams(window.location.search);
const terms = ref(urlParams.get("query"));
const searchForm = ref(null);
const searchInput = ref(null);

const props = defineProps({
  defaultOpen: {
    type: Boolean,
    default: false,
  },
  redirects: {
    type: Array,
    default: false,
  },
});

const checkForSpecialKeywords = () => {
  let redirectTo = null;
  props.redirects.map(function (redirect) {
    if (terms.value.toLowerCase().includes(redirect.searchQuery.toLowerCase())) {
      redirectTo = redirect.redirectUrl;
    }
  });
  if (redirectTo) {
    window.location.href = redirectTo;
    return false;
  }
  searchForm.value.submit();
};

const setFocus = () => {
  setTimeout(() => {
    if (searchInput.value) {
      searchInput.value.select();
    }
  }, 50);
};
</script>

<style scoped>

.header-search {
        display: none;

        @media only screen and (min-width: 1000px) {

        display: flex;
        top: 20px;
        right: 60px;
        position: absolute;
        justify-content: flex-end;

        .header-search__icon {
          margin-right: 10px;
        }

        input {
          margin: 0;
          border: 0;
          border-bottom: 1px solid #000;
          padding: 0 0.235rem 0.125rem;
          width: 200px;
        }
      }
    }
</style>
